// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tentakulum-1-js": () => import("./../../../src/pages/TENTAKULUM1.js" /* webpackChunkName: "component---src-pages-tentakulum-1-js" */),
  "component---src-pages-tentakulum-2-js": () => import("./../../../src/pages/TENTAKULUM2.js" /* webpackChunkName: "component---src-pages-tentakulum-2-js" */),
  "component---src-pages-tentakulum-3-js": () => import("./../../../src/pages/TENTAKULUM3.js" /* webpackChunkName: "component---src-pages-tentakulum-3-js" */),
  "component---src-pages-tentakulum-4-js": () => import("./../../../src/pages/TENTAKULUM4.js" /* webpackChunkName: "component---src-pages-tentakulum-4-js" */),
  "component---src-pages-tentakulum-5-js": () => import("./../../../src/pages/TENTAKULUM5.js" /* webpackChunkName: "component---src-pages-tentakulum-5-js" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-projects-jsx": () => import("./../../../src/templates/projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */)
}

